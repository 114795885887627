

.booking__video__wrapper {
    width: 100%;
    height: 100%;
    overflow: auto;
}

.booking__video__wrapper img {
    width: 100%;
    max-height: 20rem;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}


.booking__container {
    background: var(--app-booking-bg);
    padding: 10px;
}

.booking__container button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.booking__container button span {
    font-weight: 500;
    font-size: 20px;
}

.booking__container .bonus {
    padding: 10px 0px;
}

.booking__container .bonus span {
    font-weight: 600;
    font-size: 1rem;
    color: var(--app-success);
}

.booking__container .bonus .bonus__red {
    color: red !important;
} 


.booking__summary {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    background: var(--app-theme);
    border-radius: 8px;
}



.booking__summary__content article:nth-child(2) {
    font-weight: 600;
    font-size: 16px;
}

.booking__summary__content:nth-child(1) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    border-bottom: .02667rem  solid var(--app-border);
    border-right: .02667rem  solid var(--app-border);
}

.booking__summary__content:nth-child(2) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    border-bottom: .02667rem  solid var(--app-border);
}

.booking__summary__content:nth-child(3) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    border-right: .02667rem  solid var(--app-border);
}

.booking__summary__content:nth-child(4) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
}

.booking__summary__content:nth-child(5) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    border-right: .02667rem  solid var(--app-border);
    border-top: .02667rem  solid var(--app-border);
}

.booking__summary__content:nth-child(6) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    border-top: .02667rem  solid var(--app-border);
}


.booking__summary__content:nth-child(7) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    border-top: .02667rem  solid var(--app-border);
    border-right: .02667rem  solid var(--app-border);
}


.booking__summary__content:nth-child(8) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    border-top: .02667rem  solid var(--app-border);
}


.booking__description {
    background: var(--app-theme);
    padding: 10px;
    margin: 10px 0px 50px 0px;
}

.booking__description article:nth-child(1) {
    font-size: 18px;
    font-weight: 500;
    padding-bottom: 10px;
}


#do__task button {
    margin-top: 1.5rem;
}


.pay__to {
    font-size: 18px;
    font-weight: 500;
    color: var(--app-primary);
    border-bottom: 1px solid var(--app-border);
    padding-bottom: 5px;
    margin-top: -10px;
}

.custom-error-message {
    max-width: 300px;
    position: absolute;
    top: 0;
    right: 10%;
    left: 10%;
}

