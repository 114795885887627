

.login__inner__withdraw {
    background: white;
    max-width: 380px;
    padding: 35px 20px;
}

.login__inner__withdraw  button {
    margin-top: 2rem;
    width: 100%;
}


.login__inner__withdraw__history {
    background: rgb(246, 245, 245);
    max-width: 380px;
    max-height: 900px;
    padding: 35px 20px;
}

.login__inner__withdraw__content {
    background: rgb(246, 245, 245);
    max-width: 380px;
    padding: 35px 20px;
}

.toast__index {
    z-index: 99999999999 !important;
}

.transaction__history {
    display: flex;
    justify-content: space-around;
    align-items: center;
    background: var(--app-theme);
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    padding: 10px;
    border-radius: 8px;
    margin-bottom: 10px;
}

.transaction__history .history__left {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: flex-start;
}

.transaction__history .history__left span:nth-child(1) {
    font-weight: 900;
    color: var(--app-primary);
}

.transaction__history .history__left span:nth-child(2) {
    color: #999;
}

.transaction__history .history__right {
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    align-items: flex-end;
}

.transaction__history .history__right span:nth-child(1) {
    font-weight: 900;
    color: red;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
}

.transaction__history .history__right span:nth-child(2),
.transaction__history .history__right span:nth-child(3) {
    color: #999;
}
