

.main__container {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;
    border: 2px solid var(--app-secondary);
}

@media (min-width: 380px) {
    .main__container {
        width: 100%;
        height: 100%;
    }
}

@media (min-width: 480px) {
    .main__container {
        width: 100%;
        height: 100%;
    }
}


@media (min-width: 500px) {
    .main__container {
        width: 100%;
        height: 100%;
    }
}


@media (min-width: 640px) {
    .main__container {
        max-width: 400px;
        height: 100%;
    }
}


@media (min-width: 768px) {
    .main__container {
        max-width: 400px;
        height: 100%;
    }
}

@media (min-width: 1024px) {
    .main__container {
        max-width: 400px;
        height: 100%;
        border: 2px solid var(--app-secondary);
    }
}



@media (min-width: 2000px) {
    .main__container {
        max-width: 400px;
        height: 100%;
        border: 2px solid var(--app-secondary);
    }
}