


:root {
    --app-theme: #fff;
    --app-padding: 20px;
    --app-primary: #000;
    --app-secondary: #1677FF;
    --app-desc: #b8b8b8;
    --app-success: #16b901;
    --app-booking-bg: #F6F7FB;
    --app-border: #f1f1f3;
}


.app__layout {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}

a {
    text-decoration: none;
}



/* BROWSER SCROLLBAR */
::-webkit-scrollbar {
    width: 0px;
    scrollbar-width: none;
  }
  
  ::-webkit-scrollbar-track {
    box-shadow: none !important;
  }
  
  ::-webkit-scrollbar-thumb {
    background-color: none;
    outline: none !important;
  }
  
  .scroller {
    overflow-y: scroll;
    scrollbar-color: var(--app-primary);
  }
  

  /* fire fox */
* {
    scrollbar-width: none;
  }
  

  /* pagination.css */

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  list-style: none;
  padding: 0;
  margin-top: 2rem;
}

.pagination li {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 4px;
  cursor: pointer;
}

.pagination a {
  color: #333;
  text-decoration: none;
  font-weight: bold;
  padding: 8px 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.pagination a:hover {
  background-color: #eee;
}

.pagination .break {
  padding: 8px 12px;
}

.pagination .selected a {
  background-color: #007bff;
  color: #fff;
}

.pagination .disabled a {
  color: #ccc;
  pointer-events: none;
}

.pagination .previous,
.pagination .next {
  display: flex;
  border: 1px solid #ccc;
  border-radius: 4px;
  cursor: pointer;
}

.pagination .previous:hover,
.pagination .next:hover {
  background-color: #eee;
}
