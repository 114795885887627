
.record__title {
    width: 100%;
    font-size: 18px;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
    border-bottom: 1px solid var(--app-primary);
}

.record__wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 30px;
    padding: 20px;
    top: 0;
}

.record__tab {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 30px;
    max-width: 50px;
    font-size: 14px;
    font-weight: 400;
    color: var(--app-primary);
    cursor: pointer;
}

.selected__tab {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 30px;
    max-width: 50px;
    font-size: 14px;
    font-weight: 400;
    color: var(--app-secondary);
    cursor: pointer;
}


#overflow {
    overflow-y: scroll;
    overflow-x: hidden;
    padding: 20px;
    height: 100vh;
    margin-bottom: 5rem;

}