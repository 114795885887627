

.account__header {
    width: 404px;
}

.account__img {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    background: url("../img/img1.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-blend-mode: overlay;
    padding: 40px;
}

.account__img img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 1px solid var(--app-theme);
}

.account__id {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 20px;
}

.account__id__wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}



/* ACCOUNT ACCOUNT */
.account__account {
    width: 80%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 50px;
    background: #f4f4f4;
    padding: 15px 40px;
}

.account__account span {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    cursor: pointer;
}

.account__account span svg {
    font-size: 20px;
}

.account__wrapper {
    padding: 20px 30px;
}

.account__wrapper .title {
    font-weight: 600;
    font-size: 16px;
}

.account__wrapper .desc {
    font-size: 14px;
    margin-top: -30px;
}

.account__actions {
    padding: 10px 0px; 
    margin-bottom: 2.5rem;
}

.account__actions .actions {
    color: var(--app-primary);
}

.account__actions .actions p {
    display: flex;
    justify-content: center;
    align-items: center;
}

.account__actions .actions p span {
    padding-left: 10px;
    font-size: 16px;
}

.account__actions .actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
}



/* DEPOSIT MODAL */
.account__balance {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 20px;
}

.account__balance span:nth-child(1) {
    font-weight: 500;
}

.account__balance span:nth-child(2) {
    font-weight: 700;
}

.account__select {
    display: flex;
    flex-direction: column;
    padding-top: 20px;
}

.account__select select {
    padding: 10px;
    border-radius: 8px;
}

.account__select select:hover,
.account__select select:focus {
    border: 1px solid #1677FF;
}

.account__select .desc {
    font-weight: 500;
    padding-bottom: 8px;
}

.account__select__amt {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 10px;
    padding-top: 20px;
}

.selected__amt {
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--app-theme);
    border-radius: 8px;
    font-size: 16px;
    padding: 8px 10px;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
    cursor: pointer;
}

.selected__amt__clicked {
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--app-secondary);
    color: var(--app-theme);
    border-radius: 8px;
    font-size: 16px;
    padding: 8px 10px;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
    cursor: pointer;
}


.account__deposit {
    border-top: 1px solid var(--app-border);
}

.account__deposit button {
    width: 100%;
    margin-top: 2rem;
}


/* WITHDRAW */
.account__withdraw {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-top: 20px;
}

.account__withdraw .desc {
    font-weight: 400;
    padding-right: 5px;
}

.account__withdraw .amt {
    font-weight: 600;
}

.account__withdraw__wrapper {
    background: var(--app-border);
    padding: 10px;
    margin-top: 20px;
}

.account__withdraw__summary {
    display: flex;
    justify-content: space-between;
    align-items: center;
}


/* LEVEL */

.account__level {
    background: rgb(77, 68, 66);
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    height: 100vh;
    overflow-x: hidden;
    overflow-y: auto;
    z-index: 999;
}

.account__level__nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: var(--app-theme);
    padding: 20px;
}

.account__level__nav svg {
    cursor: pointer;
}

.account__level__tab {
    background: var(--app-theme);
    margin: 0px 20px 20px 20px;
    border-radius: 8px;
    padding: 0px 0px;
}

.account__level__tab .record__tab {
    max-width: 100%;
}

.account__current__level {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding: 20px 20px;
}

.account__current__level img {
    width: 50%;
    height: 50%;
}

.account__current__level .ranking {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: left;
    color: var(--app-theme);
}

.account__current__level .ranking p span:nth-child(1) {
    font-size: 40px;
}

.account__current__level .ranking p span:nth-child(2) {
    font-size: 14px;
    margin-left: -10px;
}


/* ACCOUNT LEVEL DESCRIPTION */
.account__desc__level {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding: 20px 20px;
    gap: 10px;
}

.account__level__description {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: hsla(0,0%,100%,.1);
    padding: 10px;
    border-radius: 4px;
    color: var(--app-theme);
}

.account__level__description p:nth-child(1) {
    font-size: 20px;
    font-weight: 500;
}

.account__level__description p:nth-child(2) {
    font-size: 20px;
    font-weight: 500;
    color: var( --app-secondary);
    margin-top: -20px;
}


.account__level__description p:nth-child(2) span{
    font-size: 16px;
    font-weight: 500;
}

.account__level__description img {
    width: 50%;
    height: 50%;
    margin-top: -20px;
}


.account__level__description p:nth-child(4) {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-weight: 500;
    font-size: 14px;
    margin-top: -4px;
}

.account__level__description p:nth-child(5) {
    font-weight: 500;
    font-size: 12px;
    margin-top: -12px;
}

.deposit__copy {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
}

.deposit__copy .deposit__copy__content {
    border-bottom: 1px solid #ccc;
    width: 100% !important; 
}

.deposit__copy .deposit__copy__content p:nth-child(1) {
    font-weight: 700;
    font-size: 16px;
    color: #000;
    text-align: left;
}

.deposit__copy .deposit__copy__content p:nth-child(2) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: -20px;
}

.btn__payment__desc {
    font-weight: 500;
}

.deposit__copy svg {
    cursor: pointer;
    margin-left: 10px;
}


.ant-popconfirm-message-icon {
    display: none;
}

.ant-upload-list {
    display: none;
}