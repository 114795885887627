

.nav__bottom {
    position: absolute;
    bottom: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--app-theme);
    padding: 10px 10px;
    width: 380px;
}

.nav__bottom a {
    text-decoration: none;
}

.nav__bottom article {
    padding: 0px 10px;
}

.nav__bottom .nav__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: var(--app-desc);
}

.nav__bottom .nav__icon.active {
    color: var(--app-secondary);
}

.nav__bottom .nav__icon svg {
    font-size: 30px;
}


.scrolling__text__container {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}


.traveller__container {
    width: 100%;
    height: 20rem;
    background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("../img/traveller.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-blend-mode: overlay;
}

.traveller__departure {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: var(--app-theme);
    padding-top: 15rem;
}

.traveller__departure p {
    padding: 0px 30px;
    font-size: 20px;
    font-weight: 700;
}

.traveller__departure svg {
    font-size: 20px;
    font-weight: 700;
    color: var(--app-theme) !important;
}

.traveller__departure p:nth-child(2) {
    border: 2px solid var(--app-theme);
    border-radius: 50%;
    padding: 5px 8px;
    color: var(--app-theme);
}

.traveller__details__container {
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    background: var(--app-theme);
    border-radius: 4px;
    padding: 20px;
    margin: -15px 20px 20px 20px;
}

.traveller__details__container section {
    width: 100%;
}

.traveller__details__container section article {
    padding-bottom: 05px;
}

.traveller__details__container__2 {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-top: 1rem;
}

.traveller__details__container__2 section {
    width: 32%;
}

.traveller__details__container button {
    margin-top: 2rem;
}

/* EVENT SCREEN */
.event__wrapper {
    background: var(--app-theme);
    width: 100%;
}

.event__wrapper p {
    margin-top: -15px;
}

.event__wrapper__header {
    padding: 0px 20px;
}

.event__slide__img {
    width: 100%;
    height: 38rem;
    /* overflow: hidden; */
}

.event__slide__img img {
    width: 100%;
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}



/* NEW HOME */

.Home__destination  {
    position: relative;
    width: 100%;
    overflow: hidden;
}
.Home__destination p {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: left;
    padding: 0px 30px;
}

.home__destination__image {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-x: scroll;
    width: 100%;
    padding: 0px 40px;
    gap: 10px;
}

.home__destination__image img {
    width: 100%;
    height: 13rem;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
}