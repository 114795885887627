.login__wrapper {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.login__content {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  background-image: url("../img//login_bg.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.login__inner__wrapper {
  padding: 20px;
}

.top__right {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  color: var(--app-theme);
  margin-top: -5rem;
  
}

.login__content .input__london {
  width: 100%;
  height: 40px;
}

.login__content .input__londons {
    width: 100%;
    height: 40px;
  }

.login__content .login__inner__wrapper .input__london:nth-child(2) {
    margin-top: 20rem;
}

.login__content .login__inner__wrapper .input__londons:nth-child(2) {
    margin-top: 14rem;
}


.forgot__london {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.login__content button {
  width: 100%;
  margin-top: 2rem;
}

.create__acc {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 10px;
}

.create__acc a {
  margin-left: 10px;
}

button {
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}

button:focus,
button:hover {
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}

.resend__code {
    color: var(--app-secondary);
    cursor: pointer;
}

/* RESPONSIVENESS */

@media (min-width: 380px) {
  .login__content {
    width: 100%;
    height: 100%;
  }
}

@media (min-width: 500px) {
  .login__content {
    width: 100%;
    height: 100%;
  }

  .top__right {
    margin-top: -5rem;
  }

}

@media (min-width: 640px) {
  .login__content {
    width: 400px;
    height: 100%;
  }

  .top__right {
    margin-top: -5rem;
  }
}

@media (min-width: 768px) {
  .login__content {
    width: 400px;
    height: 100%;
  }

  .top__right {
    margin-top: -12rem;
  }

  .top__right {
    margin-top: -5rem;
  }

}

@media (min-width: 1024px) {
  .login__content {
    width: 400px;
    height: 100%;
  }

  .top__right {
    margin-top: -8rem;
  }

  .login__content .login__inner__wrapper .input__london:nth-child(2) {
    margin-top: 24rem;
}

.login__content .login__inner__wrapper .input__londons:nth-child(2) {
    margin-top: 18rem;
}

}

@media (min-width: 2000px) {
  .login__content {
    width: 400px;
    height: 100%;
  }

  .top__right {
    margin-top: -8rem;
  }

  .login__content .login__inner__wrapper .input__london:nth-child(2) {
    margin-top: 24rem;
}

.login__content .login__inner__wrapper .input__londons:nth-child(2) {
    margin-top: 18rem;
}
}
